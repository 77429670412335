<template>
    <div>
        <v-tabs>
            <v-tab>
                Start
            </v-tab>
            <v-tab>
                Users
            </v-tab>
            <v-tab>
                Hotels
            </v-tab>
            <v-tab>
                Payments
            </v-tab>
            <v-tab-item style="padding: 15px">Start</v-tab-item>
            <v-tab-item style="padding: 15px;">
                <div style="display: flex;margin-bottom: 15px;">
                    <v-btn outlined small color="primary" @click="createUserOpen">
                        Create user
                    </v-btn>
                </div>
                <div>
                    <v-data-table
                            :headers="usersHeader"
                            :items="users"
                            class="elevation-1"
                            hide-default-footer
                    >
                        <template v-slot:body>

                            <tbody v-if='users.length'>
                                <tr v-for="(item, index) in users" :key="index">
                                    <td class="d-block d-sm-table-cell">{{ item.name }}</td>
                                    <td class="d-block d-sm-table-cell">{{ item.email }}</td>
                                    <td class="d-block d-sm-table-cell">{{ item.phone }}</td>
                                    <td class="d-block d-sm-table-cell">{{ countOfProjects(item.id) }}</td>
                                    <td class="d-block d-sm-table-cell">{{ $moment(item.created_at*1000).format('DD.MM.YYYY HH:mm') }}</td>
                                    <td class="d-block d-sm-table-cell">
                                        <v-menu offset-y open-on-hover bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-cog</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item link @click="loginAsOwner(item.id)">
                                                    <v-list-item-title
                                                    >Login as</v-list-item-title
                                                    >
                                                </v-list-item>
                                                <v-list-item  link v-if="!item.email_verified_at">
                                                    <v-list-item-title style="cursor: pointer"
                                                    >Verify</v-list-item-title
                                                    >
                                                </v-list-item>
                                                <v-list-item link @click="toggleBlock(item.id)">
                                                    <v-list-item-title style="cursor: pointer"
                                                    >{{item.is_blocked?'Unblock':'Block'}}</v-list-item-title
                                                    >
                                                </v-list-item>
                                                <v-list-item link @click="openEditUser(item)">
                                                    <v-list-item-title style="cursor: pointer"
                                                    >Edit</v-list-item-title
                                                    >
                                                </v-list-item>
                                                <v-list-item link @click="deleteUserOpen(item.id)">
                                                    <v-list-item-title style="cursor: pointer;color:red;"
                                                    >Delete</v-list-item-title
                                                    >
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td style="text-align: center" colspan="4">You don't have users yet, it's time to create them</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-tab-item>
            <v-tab-item style="padding: 15px">
                <div>
                    <v-data-table
                            :headers="hotelsHeader"
                            :items="hotels"
                            class="elevation-1"
                            hide-default-footer
                    >
                        <template v-slot:body>

                            <tbody v-if='hotels.length'>
                            <tr v-for="(item, index) in hotels" :key="index">
                                <td class="d-block d-sm-table-cell">{{ item.name }}</td>
                                <td class="d-block d-sm-table-cell">{{ item.address }}</td>
                                <td class="d-block d-sm-table-cell">{{ hotelEmail(item.owner_id) }}</td>
                                <td class="d-block d-sm-table-cell">{{ tariffNumeric[item.tariff_id] }}</td>
                                <td class="d-block d-sm-table-cell">
                                    <v-menu offset-y open-on-hover bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                                                <v-icon>mdi-cog</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-if="$store.state.profile.id !== item.owner_id" link @click="loginAsOwner(item.owner_id)">
                                                <v-list-item-title
                                                >Login as</v-list-item-title
                                                >
                                            </v-list-item>
                                            <v-list-item link @click="openEditHotel(item)">
                                                <v-list-item-title style="cursor: pointer"
                                                >Edit Tariff</v-list-item-title
                                                >
                                            </v-list-item>
                                            <v-list-item link @click="downloadJSON(item.id)">
                                                <v-list-item-title style="cursor: pointer"
                                                >Download JSON</v-list-item-title
                                                >
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title style="cursor: pointer"
                                                >

                                                    <label :for="item.id" style="cursor: pointer"
                                                    >Upload JSON
                                                    </label>
                                                    <input
                                                            type="file"
                                                            :id="item.id"
                                                            style="display: none"
                                                            v-on:change="setJson"
                                                    />
                                                </v-list-item-title
                                                >
                                            </v-list-item>
                                            <v-list-item link>
                                                <v-list-item-title style="cursor: pointer"
                                                >Download images</v-list-item-title
                                                >
                                            </v-list-item>
                                            <v-list-item link @click="openCloneModal(item)">
                                                <v-list-item-title style="cursor: pointer"
                                                >Clone</v-list-item-title
                                                >
                                            </v-list-item>
                                            <v-list-item link @click="deleteHotelOpen(item.id)">
                                                <v-list-item-title style="cursor: pointer;color:red;"
                                                >Delete</v-list-item-title
                                                >
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td style="text-align: center" colspan="5">You don't have hotels yet, it's time to create them</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-tab-item>
            <v-tab-item style="padding:15px;">
              <div style="margin-bottom: 15px;">
                <div>
                  <div style="display: flex;flex-direction: column;">
                    <div>
                      <label style="margin-bottom: 0;display: block;margin-right: 15px;">All standart slots: {{ allCountOfStandart }}</label>
                    </div>
                    <div>
                      <label style="margin-bottom: 0;display: block;margin-right: 15px;">All profi slots: {{ allCountOfProfi }}</label>
                    </div>
                    <div>
                      <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available standart slots: {{ countOfStandart }}</label>
                    </div>
                    <div>
                      <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available profi slots: {{ countOfProfi }}</label>
                    </div>
                  </div>
                </div>
              </div>
                <div>
                    <v-data-table
                            :headers="paymentsHeader"
                            :items="payments"
                            class="elevation-1"
                            hide-default-footer
                    >
                        <template v-slot:body>

                            <tbody v-if='payments.length'>
                            <tr v-for="(item, index) in payments" :key="index">
                              <td class="d-block d-sm-table-cell">{{item.hotel.name}} ({{ item.hotel.id }})</td>
                                <td class="d-block d-sm-table-cell">{{ item.user.email }}</td>
                                <td class="d-block d-sm-table-cell">{{ $moment(item.created_at).format('DD.MM.YYYY') }}</td>
                                <td class="d-block d-sm-table-cell">{{ tariffNumeric[item.plan] }}</td>
                              <td class="d-block d-sm-table-cell" style="text-transform: capitalize;">{{ item.type }}</td>
                                <td class="d-block d-sm-table-cell" v-if="item.type === 'card'">{{ $moment(item.updated_at).format('DD.MM.YYYY') }} - {{ $moment(item.end_date).format('DD.MM.YYYY') }}</td>
                              <td class="d-block d-sm-table-cell" v-if="item.type === 'slot'">
                                no period
                              </td>
                              <td class="d-block d-sm-table-cell" v-if="item.type === 'card'">
                                {{item.plan===2?'70':'145'}} €
                              </td>
                              <td class="d-block d-sm-table-cell" v-if="item.type === 'slot'">
                              -
                            </td>
                              <td class="d-block d-sm-table-cell" v-if="item.type === 'card'">
                              {{item.plan===2?(70*0.35):(145*0.35)}} €
                              </td>
                              <td class="d-block d-sm-table-cell" v-if="item.type === 'slot'">
                                -
                              </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td style="text-align: center" colspan="6">You don't have payments yet</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-tab-item>
        </v-tabs>
        <v-dialog
                v-model="dialogUser"
                max-width="500px"
                id="popup-registration1"
                class="popup-registration popup mfp-hide"
        >
            <div class="popup__form popup__content">
                <v-icon
                        style="top: 10px; right: 10px; position: absolute"
                        class="close__modal"
                        @click="dialogUser = false"
                >mdi-close</v-icon
                >
                <v-form
                        class="form"
                        style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
                        ref="form_add_user"
                        @submit="registerUserConfirm"
                        v-model="valid_register"
                        novalidate
                >
                    <h2 class="popup__title" style="margin-bottom: 15px;">Register user</h2>
                    <div class="form__item form__item_error">
                        <div class="form__control">
                            <v-text-field
                                    solo
                                    outlined
                                    flat
                                    type="text"
                                    :placeholder="$t('landing.popup[\'Ваше имя\']')"
                                    v-model="registerUser.name"
                                    :rules="nameRules"
                                    required
                                    validate-on-blur
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form__item">
                        <div class="form__control">
                            <v-text-field
                                    solo
                                    outlined
                                    flat
                                    type="email"
                                    placeholder="E-mail"
                                    v-model="registerUser.email"
                                    :rules="emailRules"
                                    required
                                    validate-on-blur
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form__item">
                        <div class="form__control">
                            <v-text-field
                                    solo
                                    outlined
                                    flat
                                    type="password"
                                    :placeholder="$t('landing.popup[\'Пароль\']')"
                                    v-model="registerUser.password"
                                    :rules="passwordRules"
                                    required
                                    validate-on-blur
                            ></v-text-field>
                        </div>
                    </div>
                    <div
                            style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
                    >
                        <div
                                class="v-messages theme--light error--text"
                                v-if="error_register"
                                style="margin-bottom: 20px"
                                role="alert"
                        >
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message" style="font-size: 14px">
                                    {{ error_register_message }}
                                </div>
                            </div>
                        </div>
                        <v-btn color="primary"
                               outlined
                               small type="submit" class="btn_form">
                            Create
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </v-dialog>
        <v-dialog
                v-model="editUserModal"
                max-width="500px"
                id="popup-edit"
                class="popup-registration popup mfp-hide"
        >
            <div class="popup__form popup__content">
                <v-icon
                        style="top: 10px; right: 10px; position: absolute"
                        class="close__modal"
                        @click="editUserModal = false"
                >mdi-close</v-icon
                >
                <v-form
                        class="form"
                        style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
                        v-if="editUser"
                        ref="form_edit_user"
                        @submit="editUserConfirm"
                        novalidate
                >
                    <h2 class="popup__title" style="margin-bottom: 15px;">Edit user</h2>
                    <div class="form__item form__item_error">
                        <div class="form__control">
                            <v-text-field
                                    solo
                                    outlined
                                    flat
                                    type="text"
                                    :placeholder="$t('landing.popup[\'Ваше имя\']')"
                                    v-model="editUser.name"
                                    :rules="nameRules"
                                    required
                                    validate-on-blur
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form__item">
                        <div class="form__control">
                            <v-text-field
                                    solo
                                    outlined
                                    flat
                                    type="email"
                                    placeholder="E-mail"
                                    v-model="editUser.email"
                                    :rules="emailRules"
                                    required
                                    validate-on-blur
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form__item">
                        <div class="form__control">
                            <v-text-field
                                    solo
                                    outlined
                                    flat
                                    type="phone"
                                    placeholder="Phone"
                                    v-model="editUser.phone"
                                    required
                                    validate-on-blur
                            ></v-text-field>
                        </div>
                    </div>
                    <div
                            style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
                    >
                        <v-btn color="primary"
                               outlined
                               small type="submit" class="btn_form">
                            Edit
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </v-dialog>
        <v-dialog
                v-model="editHotelModal"
                max-width="500px"
                id="popup-edit-hotel"
                class="popup-registration popup mfp-hide"
        >
            <div class="popup__form popup__content">
                <v-icon
                        style="top: 10px; right: 10px; position: absolute"
                        class="close__modal"
                        @click="editHotelModal = false"
                >mdi-close</v-icon
                >
                <v-form
                        class="form"
                        style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
                        v-if="editHotel"
                        ref="form_edit_user"
                        @submit="editHotelConfirm"
                        novalidate
                >
                    <h2 class="popup__title" style="margin-bottom: 15px;">Edit Tariff</h2>
                    <div class="form__item">
                        <div class="form__control">
                            <v-select
                                    solo
                                    outlined
                                    required
                                    dense
                                    v-model="editHotel.tariff_id"
                                    item-text="name"
                                    item-value="value"
                                    flat :items="tarrifArr">

                                <template v-slot:selection="{ item }">
                                    <div style="display: flex;justify-content: space-between;width: 100%;">
                                      <div>{{ item.name }}</div><div v-if="item.value !== 1"> Slots: {{item.tickets}}</div>
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                  <div style="display: flex;justify-content: space-between;width: 100%;">
                                      <div>{{ item.name }}</div><div v-if="item.value !== 1"> Slots: {{item.tickets}}</div>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div
                            style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
                    >
                        <v-btn color="primary"
                               outlined
                               small type="submit" class="btn_form">
                            Edit
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </v-dialog>
        <v-dialog
                v-model="cloneHotelModal"
                max-width="500px"
                id="popup-clone-hotel"
                class="popup-registration popup mfp-hide"
        >
            <div class="popup__form popup__content">
                <v-icon
                        style="top: 10px; right: 10px; position: absolute"
                        class="close__modal"
                        @click="cloneHotelModal = false"
                >mdi-close</v-icon
                >
                <v-form
                        class="form"
                        style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
                        v-if="users.length"
                        ref="form_edit_user"
                        @submit="cloneUserConfirm"
                        novalidate
                >
                    <h2 class="popup__title" style="margin-bottom: 15px;">Clone hotel</h2>
                    <div class="form__item">
                        <div class="form__control">
                            <v-select
                                    solo
                                    :error="error_clone"
                                    :error-messages="error_clone_message"
                                    outlined
                                    required
                                    dense
                                    v-model="clonedUserID"
                                    item-text="name"
                                    item-value="id"
                                    flat :items="usersFiltered">
                                <template v-slot:selection="{ item }">
                                    <div>
                                        {{ item.name }}
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div>
                                        {{ item.name }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div
                            style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
                    >
                        <v-btn color="primary"
                               outlined
                               small type="submit" class="btn_form">
                            Clone
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </v-dialog>
        <PopupSuccess ref="PopupSuccess" title="Success" :content="successMessage"/>
      <PopupDelete ref="PopupDeleteHotel" title="Are you sure about delete hotel?" @confirm="deleteHotelConfirm"/>
        <PopupDelete ref="PopupDelete" title="Are you sure about delete user?" @confirm="deleteUserConfirm"/>

    </div>
</template>

<script>
  import 'vue2-datepicker/index.css';
  import titleMixin from "../mixins/titleMixin";
    import AgencyService from "../services/agency.service";
    import PopupSuccess from "../components/Popup/PopupSuccess";
    import PopupDelete from "../components/Popup/PopupDelete";
  export default {
    name: "Partners",
    components: {PopupDelete, PopupSuccess, },
    mixins:[titleMixin],
    title(){
      return 'My Partners'
    },
    data(){
      return{
        cloneHotelModal:false,
        clonedUserID:null,
        clonedHotelID:null,
        payments:[],
        paymentsHeader:[
          { text: "Hotel", align: "left", sortable: true },
          { text: "User email", align: "left", sortable: true },
          { text: "Date", align: "left", sortable: true },
          { text: "Tariff", align: "left", sortable: true },
          {text: "Type pay", align: "left", sortable: true},
          { text: "Period", align: "left", sortable: true },
          { text: "Summary", align: "left", sortable: true },
          { text: "Fee", align: "left", sortable: true },
        ],
        tariffNumeric:{
          1:'Free',
          2:'Standart',
          3:'Profi'
        },
        editHotel:null,
        registerUser: {
          name: null,
          email: null,
          password: null,
          is_agency:0,
        },
        editUserModal:false,
        editUser:null,
        dialogUser:false,
        nameRules: [(v) => !!v || this.$t('landing.valid["Имя обязателено"]')],
        passwordRules: [
          (v) => !!v || this.$t('landing.valid["Пароль обязателен"]'),
          (v) =>
            (v && v.length >= 6) ||
            this.$t(
              'landing.valid["Пароль должен содержать не менее 6 символов"]'
            ),
          (v) =>
            (v && v.length < 30) ||
            this.$t("form['Пароль должен содержать не более 30 символов']"),
        ],
        emailRules: [
          (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('landing.valid["E-mail должен быть валидным"]'),
        ],
        users:[],
        hotels:[],
        valid_register: true,
        hotelsHeader:[
          { text: "Name", align: "left", sortable: true },
          { text: "Address", align: "left", sortable: true },
          { text: "Email", align: "left", sortable: true },
          { text: "Tariff", align: "left" },
          { text: "Options", align: "left" },
        ],
        usersHeader:[
          { text: "Name", align: "left", sortable: true },
          { text: "Email", align: "left", sortable: true },
          { text: "Phone", align: "left", sortable: true },
          { text: "Count of projects", align: "left", sortable: true },
          { text: "Registration date", align: "left", sortable: true },
          { text: "Options", align: "left" },
        ],
        error_register_message:'',
        error_register:false,
        successMessage:"",
        deleteUserID:null,
        deleteHotelID:null,
        editHotelModal:false,
        usersFiltered:[],
        availableSlots:[],
        error_clone:false,
        error_clone_message:"",
        allSlotsCount:[]
      }
    },
    methods:{
      countOfProjects(user_id){
        return this.hotels.filter(x => x.owner_id === user_id).length;
      },
      openCloneModal(hotel){
        this.clonedHotelID = hotel.id;
        this.usersFiltered = [];
        if(this.$store.state.profile.id === hotel.owner_id){
          this.usersFiltered = JSON.parse(JSON.stringify(this.users));
        }else{
          this.usersFiltered = JSON.parse(JSON.stringify(this.users.filter(x => x.id !== hotel.owner_id)))
        }
        this.cloneHotelModal = true;
      },
      async cloneUserConfirm(e){
        e.preventDefault();
        this.error_clone_message = "";
        this.error_clone = false;
        const payload = {
          hotel_id:this.clonedHotelID,
          user_id:this.clonedUserID
        };
        const data = await AgencyService.cloneHotel(payload)
        if(data?.id){
          this.hotels.push(data);
          this.successMessage = "Hotel cloned successfully!";
          this.cloneHotelModal = false;
          this.$refs.PopupSuccess.open();
          this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
        }else{
          this.error_clone_message = data.message;
          this.error_clone = true;
        }
      },
      async setJson(e) {
        let files = e.target.files || e.dataTransfer.files;
        const payload = {
          hotel_id:e.target.id,
          file:files[0]
        };
        await AgencyService.uploadHotelData(payload);
        this.successMessage = 'Hotel data refreshed successfully!';
        this.$refs.PopupSuccess.open();
      },
      async downloadJSON(hotel_id){
        const candidate = this.hotels.find((x) => x.id === hotel_id);
        const data = await AgencyService.downloadHotelData(hotel_id);
        if (candidate) {
          const blob = new Blob([JSON.stringify(data)], { type: "text/json" });
          const link = document.createElement("a");
          link.download =
            candidate.name.replaceAll(" ", "_") +
            "_" +
            this.$moment().format("DD_MM_yyyy_HH_mm") +
            ".json";
          link.href = window.URL.createObjectURL(blob);
          link.dataset.downloadurl = ["text/json", link.download, link.href].join(
            ":"
          );
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          link.dispatchEvent(evt);
          link.remove();
        }
      },
      openEditHotel(hotel){
        this.editHotel = JSON.parse(JSON.stringify(hotel));
        this.editHotelModal = true;
      },
      hotelEmail(user_id){
        if(this.$store.state.profile.id === user_id){
          return this.$store.state.profile.email
        }else{
          const candidate = this.users.find(x => x.id === user_id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              return this.users[candidateIndex].email;
            }else{
              return ""
            }
          }else{
            return ""
          }
        }
      },
      async editHotelConfirm(e){
        e.preventDefault();
        const data = await AgencyService.editHotel(this.editHotel);
        if(data[0].id){
          const candidate = this.hotels.find(x => x.id === data[0].id);
          if(candidate){
            const candidateIndex = this.hotels.indexOf(candidate);
            if(candidateIndex>=0){
              this.hotels.splice(candidateIndex,1,data[0]);
              this.successMessage  = 'Hotel edited successfully!';
              this.editHotelModal = false;
              this.$refs.PopupSuccess.open();
              this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
            }
          }
        }

      },
      async deleteHotelOpen(hotel_id){
        this.deleteHotelID = hotel_id;
        this.$refs.PopupDeleteHotel.open();
      },
      async deleteHotelConfirm(){
        const data = await AgencyService.deleteHotel(this.deleteHotelID);
        if(data.id){
          const candidate = this.hotels.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.hotels.indexOf(candidate);
            if(candidateIndex>=0){
              this.hotels.splice(candidateIndex,1);
            }
          }
        }
      },
      async toggleBlock(user_id){
        const data = await AgencyService.toggleBlockUser(user_id);
        if(data.id){
          const candidate = this.users.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              this.users.splice(candidateIndex,1,data);
            }
          }
          this.successMessage = `User ${data.name} ${data.is_blocked?'blocked':'unblocked'} successfully!`;
          this.$refs.PopupSuccess.open();
        }

      },
      async deleteUserConfirm(){
        const data = await AgencyService.deleteOwner(this.deleteUserID);
        if(data.id){
          const candidate = this.users.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              this.users.splice(candidateIndex,1);
            }
          }
        }
      },
      deleteUserOpen(user_id){
        this.deleteUserID = user_id;
        this.$refs.PopupDelete.open();
      },
      async loginAsOwner(user_id){
        const data = await AgencyService.loginAsOwner(user_id);
        localStorage.removeItem('token');
        window.open(`${process.env.VUE_APP_LINK}/?admin_token=${data.agency_token}`,'_self');
      },
      async editUserConfirm(e){
        e.preventDefault();
        const data = await AgencyService.editOwner(this.editUser);
        if(data.id){
          const candidate = this.users.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              this.users.splice(candidateIndex,1,data);
            }
          }
        }
        this.successMessage = "User edited successfuly!";
        this.editUserModal = false;
        this.$refs.PopupSuccess.open();
      },
      openEditUser(user){
        this.editUser = JSON.parse(JSON.stringify(user));
        this.editUserModal = true;
      },
      async registerUserConfirm(e){
        this.error_register = false;
        this.error_register_message = "";
        e.preventDefault();
        if (this.$refs.form_add_user.validate()) {
          const data = await AgencyService.postRegister(this.registerUser);
          if(data.id){
            this.users.push(data);
            this.successMessage = "User created successfully!";
            this.dialogUser = false;
            this.$refs.PopupSuccess.open()
          }else{
            this.error_register_message = data.message;
            this.error_register = true;
          }
        }else{
          // this.va
        }
      },
      createUserOpen(){
        this.register = {
          name: null,
          email: null,
          password: null,
        };
        this.dialogUser = true;
      }
    },
    computed:{
      allCountOfStandart(){
        const candidate = this.allSlotsCount.find(x => x.plan_id === 2);
        if(candidate){
          return candidate.quantity
        }else{
          return 0
        }
      },
      allCountOfProfi(){
        const candidate = this.allSlotsCount.find(x => x.plan_id === 3);
        if(candidate){
          return candidate.quantity
        }else{
          return 0
        }
      },
      countOfStandart(){
        const candidate = this.availableSlots.find(x => x.plan_id === 2);
        if(candidate){
          return candidate.quantity
        }else{
          return 0
        }
      },
      countOfProfi(){
        const candidate = this.availableSlots.find(x => x.plan_id === 3);
        if(candidate){
          return candidate.quantity
        }else{
          return 0
        }
      },
    },
    async mounted(){
      this.users = await AgencyService.getUsers();
      this.hotels = await AgencyService.getHotels();
      this.payments = await  AgencyService.getPayments();
      this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
      this.allSlotsCount = await AgencyService.getSlotsQuantity(this.$store.state.profile.id);
    }
  }
</script>

<style scoped>

</style>
