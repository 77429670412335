<template>
    <v-dialog max-width="410px" 
        v-model="dialog"
        @click:outside="close"
    >
        <div class="popup__content popup__content_success">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title" style="margin-bottom: 10px;">{{title}}</h5>
            <h5 class="popup__text">{{content}}</h5>
            <v-btn class="bnt btn_primary" 
                color="primary" 
                depressed
                block
                @click="close"
            >
                {{$t('app["Закрыть"]')}}
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: String,
        content: String
    },
    data: () => ({
        dialog: false,
    }),
  mounted() {
  },
  methods: {
        open() {
            this.dialog = true;
        },
      async close() {
            if (this.$route.name === 'StickersCreate') {
                await this.$router.push({name: 'stickers', params: {lang: this.$route.params.lang}});
                this.dialog = false;
            }else if(this.$route.name === 'ObjectsCreate'){
              await this.$router.push({
                name: "objects",
                params: { lang: this.$route.params.lang, new: true },
              });
              this.dialog = false;
            }
            else {
                this.dialog = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.popup__content_success {
    padding-top: 140px !important;
    background: #fff url(~@/assets/icons/success.svg) center 50px/64px no-repeat;
}
.popup__content {
  position: relative;
  padding: 50px 40px 40px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 25px 0 rgba(0, 59, 85, 0.06);
}

.popup__close.v-btn {
  position: absolute;
  top: 8px;
  right: 8px;
}
.popup__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}

.popup__text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom:30px;
    text-align: center;
    color: #000;

    span {
        color: #000;
    }
}
</style>
